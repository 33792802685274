<template>
  <DesignStudioDSHeader />
  <main class="main-wrapper">
    <slot />
  </main>
  <DesignStudioDSFooter />
  <ModulesUserLogin
    :show="showLoginModal"
    @update:show="
      $event ? user.showLoginModal() : user.hideLoginModal()
    "
    @hide="user.hideLoginModal"
  />
  <CookieModal />
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import {useBaseStore} from '~/modules/BaseModule/base.store';
import CookieModal from '~/modules/Cookies/components/CookieModal.vue';
import {useUserStore} from '~/modules/User/user.store';
import {useLauncherInfoStore} from '~/store/launcher-info';

const launcherInfo = useLauncherInfoStore();
const user = useUserStore();
const showLoginModal = computed(() => user.isShowLoginModal);
const userInfo = ref({username: ''});

if (useCookie('df').value) {
  user.getProfile();
}

onBeforeMount(async () => {
  const f = async () => await launcherInfo.update();
  await f();
});

user.$subscribe((_, state) => {
  if (!state.error) {
    if (state.isLoggedIn) {
      user.hideLoginModal();
    }
    // user.isLoggedIn = true
    userInfo.value = state;
  }
});
</script>

<style lang="scss" scoped></style>
